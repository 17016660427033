<template>
	<b-card
		cols=12
		style="max-height: 18rem; max-width:150rem"
		overlay
		:title="infos.title" 
		:img-src="backGround"
		img-height="150rem"
	>
		<b-card-text>
			{{ infos.content }}
		</b-card-text>
	</b-card>
</template>
<script>
import { BCardText, BCard,} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
export default {
	name:"AppHeaderInfoAdminList",
	directives: {
		Ripple,
	},
	components: {
		BCardText,
		BCard
	},
	data() {
		return {
			backGround : require('@/assets/images/banner/banner-6.jpg')
		};
	},
	props: {
		infos: {
			type: Object,
		},
		icon: {
			type: String,
			default: "",
		},
	},
};
</script>
