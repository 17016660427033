var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-quiz-question-edit",attrs:{"id":"modal-quiz-question-edit","title":"Edition"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.onSubmit}},[_vm._v("Enregistrer")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"danger","size":"sm mr-1"},on:{"click":_vm.hideModal}},[_vm._v("Fermer")])],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer"},[_c('b-form',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Question")]),_c('b-form-input',{attrs:{"placeholder":"Tapez votre question"},model:{value:(_vm.quizQuestion.title),callback:function ($$v) {_vm.$set(_vm.quizQuestion, "title", $$v)},expression:"quizQuestion.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br'),_c('label',[_vm._v("Ajouter les propositions du QCM")]),(_vm.quizQuestion.type == 'QCM')?_c('b-form-tags',{staticClass:"mb-2",attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var tags = ref.tags;
          var inputId = ref.inputId;
          var tagVariant = ref.tagVariant;
          var addTag = ref.addTag;
          var removeTag = ref.removeTag;
return [_c('b-input-group',{staticClass:"mb-2"},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":inputId,"placeholder":"Ajouter les propositions du QCM"},model:{value:(_vm.newTag),callback:function ($$v) {_vm.newTag=$$v},expression:"newTag"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){addTag(_vm.newTag), _vm.addList(_vm.newTag)}}},[_vm._v("Ajouter")])],1)],1),_c('div',{staticClass:"d-inline-block",staticStyle:{"font-size":"1.5rem"}},_vm._l((tags),function(tag){return _c('b-form-tag',{key:tag,staticClass:"mr-1",attrs:{"title":tag,"variant":tagVariant},on:{"remove":function($event){removeTag(tag), _vm.removeList(tag)}}},[_c('p',{staticStyle:{"white-space":"break-spaces"}},[_vm._v(" "+_vm._s(tag)+" ")])])}),1)]}}],null,false,3485707146),model:{value:(_vm.choices),callback:function ($$v) {_vm.choices=$$v},expression:"choices"}}):_vm._e(),(_vm.quizQuestion.type == 'QCM' && this.choices.length < 2)?_c('small',{staticClass:"text-danger"},[_vm._v("Il faut minimum 2 propositions pour un QCM")]):_vm._e(),_c('br'),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',[_vm._v("La bonne réponse")]),_c('b-form-select',{attrs:{"options":_vm.listChoice},model:{value:(_vm.chooseRight.name),callback:function ($$v) {_vm.$set(_vm.chooseRight, "name", $$v)},expression:"chooseRight.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br'),_c('br'),_c('label',[_vm._v("Info")]),_c('b-form-input',{attrs:{"placeholder":"Enter your name"},model:{value:(_vm.quizQuestion.infos),callback:function ($$v) {_vm.$set(_vm.quizQuestion, "infos", $$v)},expression:"quizQuestion.infos"}}),_c('br')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }