import { api } from "@/services/axios";

export const Admin = {
	methods: {
		// API get all list object
		async getAllAdmin() {
			try {
				const response = await api.get("admin/adminList");
				//console.log('test response', response)
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},
		async updateAdmin(id, data) {
			try {
				console.log("test update id", id);
				console.log("test update data", data);
				const values_formatted = {
					firstname: data.firstname,
					lastname: data.lastname,
					email: data.email,
					nickname: data.nickname,
				};
				const response = await api.post(`admin/${id}`, values_formatted);
				console.log("test API update", response);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},
		async createAdmin(data) {
			console.log("test API create", data);
			try {
				const values_formatted = {
					firstname: data.firstname,
					lastname: data.lastname,
					email: data.email,
					password: data.password,
				};
				const response = await api.post("admin", values_formatted);
				console.log("test API response crweate ", response);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},
		async deleteAdmin(id) {
			try {
				const response = await api.delete(`admin/${id}`);
				console.log("test delete", response);
				console.log("test delete id", id);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},
		async getAllUsers() {
			try {
				const response = await api.get("admin/userList");
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},
		async siginInAdmin(data) {
			try {
				const response = await api.post("auth/admin/signin", data);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async getAllSpeaker() {
			try {
				const response = await api.get("auth/userList");
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async getCurrentSpeaker() {
			try {
				const response = await api.get("auth/me");
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async createSpeaker(data) {
			const body = {
				firstname: data.firstname,
				lastname: data.lastname,
				email: data.email,
				password: "test",
				biography: data.biography,
				speciality: data.speciality,
			};
			try {
				const response = await api.post("auth/admin/user/create", body);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async createViewer(data) {
			const body = {
				firstname: data.firstname,
				lastname: data.lastname,
				email: data.email,
				password: "test",
				number_ceu: data.number_ceu,
				number_cfc: data.number_cfc,
				aba_online_id: parseInt(data.aba_online_id),
			};
			try {
				const response = await api.post(`webinars/${data.id}/user/add`, body);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async updateSpeaker(data) {
			const body = {
				firstname: data.firstname,
				lastname: data.lastname,
				email: data.email,
				biography: data.biography,
				speciality: data.speciality,
				status: data.status,
			};
			try {
				const response = await api.post(`auth/admin/user/${data.id}`, body);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async updateSpeakerStatus(data) {
			const body = {
				status: data.status,
			};
			try {
				const response = await api.post(
					`auth/admin/user/${data.id}/status`,
					body
				);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async deleteSpeaker(idSpeaker) {
			try {
				const response = await api.delete(`auth/admin/user/${idSpeaker}`);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async sendEmailSpeaker(idSpeaker) {
			try {
				const response = await api.post(
					`auth/admin/user/${idSpeaker}/sendTemporaryPass`
				);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async uploadIMG(event, id, target) {
			let data = new FormData();
			data.append("name", "my-picture");
			data.append("file", event.target.files[0]);
			data.append("target", target);
			data.append("targetId", id);

			try {
				const response = await api.post(`media`, data);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async uploadMultiFile(event, id, target) {
			let data = new FormData();
			data.append("name", "my-picture");
			for (var file of event.target.files) {
				data.append("file", file);
			}
			data.append("target", target);
			data.append("targetId", id);

			try {
				const response = await api.post(`media/multi`, data);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async getIMG(id) {
			console.log("id");
			console.log(id);
			try {
				const response = await api.get(`media/${id}`, {
					responseType: "arraybuffer",
				});
				let raw = Buffer.from(response.data).toString("base64");

				return {
					status: 1,
					datas: "data:" + response.headers["content-type"] + ";base64," + raw,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async getAllWebinars() {
			try {
				const response = await api.get("webinars");
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async getWebinar(id) {
			try {
				const response = await api.get(`webinars/ ${id}`);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async createWebinar(data) {
			try {
				const response = await api.post("/webinars", data);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async updateWebinar(webinar) {
			try {
				const response = await api.post(`webinars/${webinar.id}`, webinar);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async deleteWebinar(idWebinar) {
			try {
				const response = await api.delete(`webinars/${idWebinar}`);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async deleteWebinarDoc(idDoc) {
			try {
				const response = await api.delete(`webinars/webinar_docs/${idDoc}`);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async sendWebinarEmail(data) {
			try {
				const response = await api.post(`webinar_mails/sendMessage`, data);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async sendTestEmail(id, type, email) {
			let data = {
				webinar_id: id,
				email: email,
			};
			try {
				const response = await api.post(
					`webinar_mails/sendTestMail/${type}`,
					data
				);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async getViewersByWebinarId(id) {
			try {
				const response = await api.get(`webinars/${id}/users`);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async editViewersById(id, datas) {
			try {
				const payload = {
					id: id,
					firstname: datas.firstname,
					lastname: datas.lastname,
					email: datas.email,
					number_cfc: datas.number_cfc,
					number_ceu: datas.number_ceu,
					presence_percentage: datas.presence_percentage,
					aba_online_id: datas.aba_online_id,
				};
				const response = await api.post(`webinars/userEdit/${id}`, payload);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async sendViewerPostQuizz(idUser, idWebinar) {
			console.log(idWebinar);
			try {
				const response = await api.post(
					`webinar_mails/sendPostQuizzMail/${idUser}`
				);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async sendViewerPreQuizz(idUser, idWebinar) {
			console.log(idWebinar);
			try {
				const response = await api.post(
					`webinar_mails/sendPreQuizMail/${idUser}`
				);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async sendViewerSurvey(idUser, idWebinar) {
			console.log(idWebinar);
			try {
				const response = await api.post(
					`webinar_mails/sendSurveyMail/${idUser}`
				);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async getAllSurveys() {
			try {
				const response = await api.get("surveys");
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async getAllQuiz() {
			try {
				const response = await api.get("surveys/quizz");
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async getSurvey(id) {
			try {
				const response = await api.get(`surveys/ ${id}`);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async createSurvey(data) {
			console.log(JSON.stringify(data));
			try {
				const response = await api.post("surveys", data);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async updateSurvey(idSurvey, name, description, type) {
			const body = {
				name: name,
				description: description,
				type: type,
			};

			try {
				const response = await api.post(`surveys/${idSurvey}`, body);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async deleteSurvey(idSurvey) {
			try {
				const response = await api.delete(`surveys/${idSurvey}`);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async createSurveyQuestion(idSurvey, surveyQuestion) {
			try {
				const response = await api.post(
					`surveys/${idSurvey}/questions`,
					surveyQuestion
				);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async updateSurveyQuestion(idSurvey, surveyQuestion) {
			try {
				const response = await api.post(
					`surveys/${idSurvey}/questions/${surveyQuestion.id}`,
					surveyQuestion
				);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async deleteSurveyQuestion(idSurvey, idQuestion) {
			try {
				const response = await api.delete(
					`surveys/${idSurvey}/questions/${idQuestion}`
				);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async loginToFront(idWebinar) {
			const data = { idWebinar };

			try {
				const response = await api.post(`auth/AdminLoginToFront`, data);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async postGenerateCertificate(webinarId, webinar_user) {
			try {
				const response = await api.post(`pdf-generator`, {
					webinarId: +webinarId,
					webinar_user: +webinar_user,
				});
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async getAnswerByWebinarAndSurvey(surveyId, webinarId) {
			try {
				const response = await api.get(`answers/${surveyId}/${webinarId}`);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async getRecordsByWebinarId(id) {
			try {
				const response = await api.get(`webinars/record/published/${id}`);
				if (response.data.length === 0) {
					return await this.startRecordByWebinarId(id);
				}
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},
		async startRecordByWebinarId(webinarId) {
			try {
				const webinar = await this.getWebinar(webinarId);

				const response = await api.get(
					`webinars/record/convert/${webinar.datas.room_info.internalMeetingID}/${webinarId}`
				);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async updateRecordtoDefault(webinarId, recordId) {
			try {
				const response = await api.post(`webinars/record/setdDefault`, {
					webinarId,
					recordId,
				});
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async deleteOneRecord(recordId) {
			try {
				const response = await api.delete(`webinars/record/${recordId}`);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},

		async forceSignIn(data) {
			try {
				const response = await api.post(`auth/forceSignIn`, data);
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},
	},
};
