<template>
  <div>
    <AddHeaderTitle :Header="Header" />
    <AppHeaderInfoAdminList :infos="infos" />

    <label> Titre </label>
    <b-form-input
      v-model="quizData.name"
      placeholder="Taper le nom du quiz"
    ></b-form-input>
    <small v-if="!quizData.name" class="text-danger"
      >Ce champ ne peut pas être vide</small
    >

    <br />

    <label> Description </label>
    <b-form-input
      v-model="quizData.description"
      placeholder="Enter your name"
    ></b-form-input>
    <br />

    <div class="text-center">
      <b-button variant="success" @click="update()">
        Enregistrer les modifications
      </b-button>
    </div>

    <b-row align-h="start">
      <b-button
        variant="primary "
        class="mb-2 mr-2"
        v-b-modal.modal-quiz-question-create
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-left">Créer une question </span>
      </b-button>
    </b-row>

    <b-table striped hover :items="quizData.questions" :fields="fields">

      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(choices)="question">
        <div  v-for="choice in question.item.choices" :key="choice.name">
                {{choice.name}}
        </div> 
      </template>

      <template #cell(action)="list">
        <b-button
          v-b-modal.modal-quiz-question-edit
          @click="setDataQuiz(list.item)"
          variant="flat-primary"
          size="sm"
          class=""
        >
          <feather-icon icon="EditIcon" />
         
        </b-button>

        <b-button
          v-b-modal.modal-sm
          variant="flat-danger"
          size="sm"
          @click="sendInfo(list.item)"
        >
          <feather-icon icon="Trash2Icon" />
         
        </b-button>

    
      </template>
    </b-table>

    <b-modal id="modal-sm" centered size="sm" title="Information">
      <template #default>
        Êtes-vous sûr de vouloir supprimer la question "{{ question.title }}" ?
      </template>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="cancel()">
          Annuler
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          @click="deleteQuestionId(question.id)"
        >
          Supprimer
        </b-button>
      </template>
    </b-modal>

    <quiz-question-edit-modal
      :quizQuestion="quizQuestion"
      :quizData="quizData"
    >
    </quiz-question-edit-modal>
    <quiz-question-create-modal @clicked="onCreate" :quizData="quizData">
    </quiz-question-create-modal>
  </div>
</template>

<script>
import AddHeaderTitle from "@/app/views/components/AppHeaderTitle.vue";
import AppHeaderInfoAdminList from "@/app/views/components/AppHeaderInfoAdminList.vue";
import { BTable, BButton, BRow, BFormInput } from "bootstrap-vue";

import QuizQuestionCreateModal from "./quiz-question-create-modal.vue";
import QuizQuestionEditModal from "./quiz-question-edit-modal.vue";

import { Admin } from "@/mixins/admin.js";

export default {
  name: "Quiz-detail",

  components: {
    BTable,
    AddHeaderTitle,
    AppHeaderInfoAdminList,
    BButton,
    BRow,
    BFormInput,
    QuizQuestionCreateModal,
    QuizQuestionEditModal,
  },

  data() {
    return {
      Header: {
        config: "Admin",
        listLink: "/admin/quiz/list",
        list: "quiz-liste",
      },
      infos: {
        title: "Edition d'un quiz",
        content: "Texte descptif ",
      },

      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        {
          key: "index",
          label: "N°",
          sortable: false,
          thStyle: { width: "3%" },
        },
        {
          key: "title",
          label: "Questions",
          sortable: true,
        },
        {
          key: "choices",
          label: "Proposition",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "Action",
          label: "Action",
          sortable: false,
          thClass: "text-right",
          tdClass: "text-right",
          thStyle: { width: "10%" },
        },
      ],
      quizId: this.$route.params.id,
      quizData: {},
      quizQuestion: {},
      question: {},
      listSpeaker: [],
      selected: "",
    };
  },

  mixins: [Admin],

  mounted() {
    this.loadList();
  },

  methods: {
    onCreate(value) {
      this.quizData.questions.push(value);
    },

    async loadList() {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
      });
      try {
        const response = await this.getSurvey(this.quizId);
        if (response.status == 1) {
          this.quizData = response.datas;
        } else {
          this.$sweetError("AF-52");
        }
        loader.hide();
      } catch {
        loader.hide();
        this.$sweetError("AF-52");
      }
    },

    setDataQuiz(quizData) {
      this.quizQuestion = quizData;
    },

    async update() {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
      });
      if (this.quizData.name) {
        try {
          const response = await this.updateSurvey(
            this.quizData.id,
            this.quizData.name,
            this.quizData.description,
            this.quizData.type
          );
          if (response.status == 1) {
            this.$sweetNotif("Modification réussie");
          } else {
            this.$sweetError("AF-51");
          }
          loader.hide();
        } catch {
          loader.hide();
          this.$sweetError("AF-51");
        }
      } else {
        loader.hide();
      }
    },

    sendInfo(questionInfo) {
      this.question = questionInfo;
    },

    async deleteQuestionId(idQuestion) {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
      });
      this.$bvModal.hide("modal-sm");
      try {
        const res = await this.deleteSurveyQuestion(this.quizData.id, idQuestion);
        if (res.status == 1) {
          this.$sweetNotif("Modification réussie");
          this.quizData.questions = this.quizData.questions.filter((question) => question.id !== idQuestion);
        } else {
          this.$sweetError("AF-51");
        }
        loader.hide();
      } catch {
        loader.hide();
        this.$sweetError("AF-51");
      }
    },

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

